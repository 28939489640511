<template>
    <page-layout-nolo :loading="isLoading" :title="$t('personalDetails')" :back="$t('btn.backToYourAccount')">
        <v-container>
            <v-card class="mb-10 py-4 px-2 elevation-8">
                <v-card-text>
                    <personal-details-edit unique-email v-model="personalDetails" @valid="v => valid = v"/>
                </v-card-text>
                <v-card-actions class="pt-0 px-4">
                    <v-row dense>
                        <v-col cols="12" sm="4" class="pt-0 pb-2">
                            <v-btn @click="cancel" outlined block color="secondary" class="mr-2">{{ $t('btn.cancel') }}</v-btn>
                        </v-col>
                        <v-col cols="12" sm="5" class="pt-0 pb-2">
                            <v-btn @click="save" :disabled="!valid" block color="primary">{{ $t('btn.saveDetails') }}</v-btn>
                        </v-col>
                    </v-row>

                </v-card-actions>
            </v-card>
        </v-container>
    </page-layout-nolo>
</template>

<script>

    import APICustomer from "../../api/APICustomer";

    import PersonalDetailsEdit from "../../components/Account/PersonalDetailsEdit";

    export default {
        name: "PersonalDetails",
        components: {PersonalDetailsEdit},
        data() {
            return {
                isLoading: false,
                personalDetails: {
                    firstName: '',
                    lastName: '',
                    phoneNumber: '',
                    email: ''
                },
                valid: false
            }
        },
        mounted() {
            this.personalDetails.firstName = this.customer.firstName;
            this.personalDetails.lastName = this.customer.lastName;
            this.personalDetails.email = this.customer.email;
            this.personalDetails.phoneNumber = this.customer.phoneNumber;
        },
        methods: {
            cancel() {
                this.$router.back();
            },
            save() {
                const me = this;
                APICustomer.updatePersonalDetails(this, this.personalDetails)
                    .then(() => {
                        me.customer = {
                            ...me.customer,
                            ...me.personalDetails
                        }
                        me.$router.back();
                    })
                    .catch(
                        () => {
                            me.appShowOops()
                        }
                    )
            }
        },
        computed: {
            customer: {
                get() {
                    return this.$store.getters['session/customer'];
                },
                set(newValue) {
                    this.$store.commit('session/customer', newValue)
                }
            }
        }
    }
</script>

<style scoped>

</style>
